import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Breadcrumb, message } from 'antd';
import { Link } from 'react-router-dom';
import Filtro from '../../Components/Filtro';
import { api } from '../../services/apiClient';
import { useAuth } from '../../contexts/AuthContext';
import PedidosRestauranteTable from '../../Components/PedidosRestauranteTable';
import PedidoRestauranteModal from '../../Components/PedidoRestauranteModal'; // Importe o novo modal

const PedidosRestaurante = () => {
  const { user } = useAuth();
  const [modalTitle, setModalTitle] = useState('Adicionar Categoria');
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [itens, setItens] = useState([]); // Alterado para itens
  const [codigoPedido, setCodigoPedido] = useState('');
  const [valor, setValor] = useState('');
  const [statusPedido, setStatusPedido] = useState('');
  const [modalAction, setModalAction] = useState('Adicionar');
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDataPedidosRestaurante();
  }, []);

  const fetchDataPedidosRestaurante = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/restaurante/pedidos/${user.restaurante.id}`);
      console.log('response pedido', response.data.message); 

      setData(response.data.message);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar os checkins!');
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    console.log('record', record);
    setModalTitle(`Pedido`);
    setModalAction('Salvar');
    setNomeUsuario(record.nomeUsuario);
    setCreatedAt(record.createdAt);
    setValor(record.valor);
    setItens(record.itens); // Recebe os itens do pedido
    setStatusPedido(record.statusPedido);
    setCodigoPedido(record.id);
    setOpen(true);
  };

  const filtroOptions = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'inativo', label: 'Inativo' },
  ];

  // Função para lidar com a impressão de um pedido específico
  const handlePrint = (order) => {
    // Envie os dados do pedido para o Electron via IPC
    window.electronAPI.printOrder(order);
    message.success(`Pedido ${order.codigoPedido} enviado para impressão.`);
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  {/* ... SVG Path ... */}
                </svg>
              </Link>
            ),
          },
          { title: <a>Gerenciamento</a> },
          { title: <span>Pedidos</span> },
        ]}
      />

      <Filtro options={filtroOptions} showUsuarioInput={true} showTipoSelect={true} showButtonFiltrar={true} />

      <PedidosRestauranteTable
        handlePrint={handlePrint} // Passa a função handlePrint como prop
        data={data}
        handleEdit={handleEdit}
      />

      <PedidoRestauranteModal
        open={open}
        loading={loading}
        modalTitle={modalTitle}
        codigoPedido={codigoPedido}
        statusPedido={statusPedido}
        nomeUsuario={nomeUsuario}
        createdAt={createdAt}
        itens={itens} // Passa os itens para o modal
        valor={valor}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default PedidosRestaurante;
