import React from 'react';
import { Modal, Input, Button, Select, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import InputMask from 'react-input-mask';
import styles from './styles.module.scss';

const RestauranteModal = ({
    loading,
    restauranteModalOpen,
    setRestauranteModalOpen,
    handleFormSubmit,
    fileList,
    onChange,
    onPreview,
    openAddCategoriaModal,
    nomeRestaurante,
    setNomeRestaurante,
    nomeRestauranteFantasia,
    setNomeRestauranteFantasia,
    docRestaurante,
    setDocRestaurante,
    emailRestaurante,
    setEmailRestaurante,
    telefoneRestaurante,
    setTelefoneRestaurante,
    categoriaRestaurante,
    setCategoriaRestaurante,
    categoriaList,
    recebimentoRestaurante,
    setRecebimentoRestaurante,
    recebimentoList,
    proprietario,
    setProprietario,
    proprietarioList,
    cepRestaurante,
    handleCepChange,
    logradouroRestaurante,
    setLogradouroRestaurante,
    numeroRestaurante,
    setNumeroRestaurante,
    complementoRestaurante,
    setComplementoRestaurante,
    bairroRestaurante,
    setBairroRestaurante,
    cidadeRestaurante,
    setCidadeRestaurante,
    estadoRestaurante,
    setEstadoRestaurante,
    favorecidoRestaurante,
    setFavorecidoRestaurante,
    cpfcnpjFavorecido,
    setCpfcnpjFavorecido,
    banco,
    setBanco,
    bancoList,
    tipo,
    setTipo,
    tipoList,
    agencia,
    setAgencia,
    digito,
    setDigito,
    conta,
    setConta,
    chavePix,
    customRequestHandler,
    setChavePix
}) => {


    // Função para limpar todos os campos do formulário
    const resetForm = () => {
        setNomeRestaurante('');
        setNomeRestauranteFantasia('');
        setDocRestaurante('');
        setEmailRestaurante('');
        setTelefoneRestaurante('');
        setCategoriaRestaurante(null);
        setRecebimentoRestaurante(null);
        setProprietario(null);
        handleCepChange({ target: { value: '' } }); // Limpa o CEP e faz as atualizações associadas
        setLogradouroRestaurante('');
        setNumeroRestaurante('');
        setComplementoRestaurante('');
        setBairroRestaurante('');
        setCidadeRestaurante('');
        setEstadoRestaurante('');
        setFavorecidoRestaurante('');
        setCpfcnpjFavorecido('');
        setBanco(null);
        setTipo(null);
        setAgencia('');
        setDigito('');
        setConta('');
        setChavePix('');
        onChange({ fileList: [] }); // Limpa o upload de arquivos
    };

    // Manipula o fechamento do modal
    const handleCloseModal = () => {
        resetForm();
        setRestauranteModalOpen(false);
    };


    return (
        <Modal
            title='Cadastrar Restaurante'
            footer={
                <div>
                    <Button type='primary' onClick={handleFormSubmit}>
                        Cadastrar
                    </Button>
                    <Button type='secondary' onClick={() => handleCloseModal()}>
                        Cancelar
                    </Button>
                </div>
            }
            loading={loading}
            open={restauranteModalOpen}
            width={'70%'}
            onCancel={() => handleCloseModal()}
        >
            <div className={styles.containerModal}>

                <div className={styles.containerUpload}>
                    <ImgCrop rotationSlider>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            customRequest={customRequestHandler}
                        >
                            {fileList.length < 1 && '+ Upload'}
                        </Upload>
                    </ImgCrop>
                </div>
                <section className={styles.section}>
                    <h2>Dados da Empresa</h2>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <label>Proprietário</label>
                            <Select className={styles.select} value={proprietario} placeholder='Selecione...' onChange={(value) => setProprietario(value)} style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >

                                {proprietarioList.map((proprietario) => (
                                    <Select.Option key={proprietario.id} value={proprietario.value}>
                                        {proprietario.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className={styles.col}>
                            <label>Nome</label>
                            <Input
                                value={nomeRestaurante}
                                onChange={(e) => {
                                    setNomeRestaurante(e.target.value);
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.col}>
                            <label>Nome Fantasia</label>
                            <Input
                                value={nomeRestauranteFantasia}
                                onChange={(e) => {
                                    setNomeRestauranteFantasia(e.target.value);
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.col}>
                            <label>CNPJ</label>
                            <InputMask
                                mask="99.999.999/9999-99"
                                value={docRestaurante}
                                onChange={(e) => setDocRestaurante(e.target.value)}
                                style={{ width: '100%' }}
                            >
                                {(inputProps) => <Input {...inputProps} type="text" />}
                            </InputMask>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <label>E-mail</label>
                            <Input value={emailRestaurante} onChange={(e) => setEmailRestaurante(e.target.value)} style={{ width: '100%' }} />
                        </div>
                        <div className={styles.col}>
                            <label>Telefone</label>
                            <InputMask
                                mask="(99) 99999-9999"
                                value={telefoneRestaurante}
                                onChange={(e) => setTelefoneRestaurante(e.target.value)}
                                style={{ width: '100%' }}
                            >
                                {(inputProps) => <Input {...inputProps} type="text" />}
                            </InputMask>
                        </div>
                        <div className={styles.col}>
                            <label>Categoria</label>
                            <Select className={styles.select} value={categoriaRestaurante} placeholder='Selecione...' onChange={(value) => setCategoriaRestaurante(value)} style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {categoriaList.map((categoria) => (
                                    <Select.Option key={categoria.id} value={categoria.value}>
                                        {categoria.titulo}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className={styles.buttonCategoria}>
                            <Button className={styles.buttonCategoria} onClick={openAddCategoriaModal}>+</Button>
                        </div>
                    </div>
                </section>
                <section className={styles.section}>
                    <h2>Endereço</h2>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <label>CEP</label>
                            <InputMask
                                mask="99999-999"
                                value={cepRestaurante}
                                onChange={handleCepChange}
                                style={{ width: '100%' }}
                            >
                                {(inputProps) => <Input {...inputProps} type="text" />}
                            </InputMask>
                        </div>
                        <div className={styles.col}>
                            <label>Logradouro</label>
                            <Input value={logradouroRestaurante} onChange={(e) => setLogradouroRestaurante(e.target.value)} style={{ width: '30vw' }} />
                        </div>
                        <div className={styles.col}>
                            <label>N°</label>
                            <Input value={numeroRestaurante} onChange={(e) => setNumeroRestaurante(e.target.value)} style={{ width: '100%' }} />
                        </div>
                        <div className={styles.col}>
                            <label>Complemento</label>
                            <Input value={complementoRestaurante} onChange={(e) => setComplementoRestaurante(e.target.value)} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <label>Bairro</label>
                            <Input value={bairroRestaurante} onChange={(e) => setBairroRestaurante(e.target.value)} style={{ width: '100%' }} />
                        </div>
                        <div className={styles.col}>
                            <label>Cidade</label>
                            <Input value={cidadeRestaurante} onChange={(e) => setCidadeRestaurante(e.target.value)} style={{ width: '100%' }} />
                        </div>
                        <div className={styles.col}>
                            <label>UF</label>
                            <Input value={estadoRestaurante} onChange={(e) => setEstadoRestaurante(e.target.value)} style={{ width: '100%' }} />
                        </div>
                    </div>
                </section>
                <section className={styles.section}>
                    <h2>Dados Bancários</h2>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <label>Tipo de Recebimento</label>
                            <Select className={styles.select} value={recebimentoRestaurante} placeholder='Selecione...' onChange={(value) => setRecebimentoRestaurante(value)} style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }>
                                {recebimentoList.map((recebimento) => (
                                    <Select.Option key={recebimento.id} value={recebimento.value}>
                                        {recebimento.titulo}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>

                        <div className={styles.col}>
                            <label>Favorecido</label>
                            <Input
                                value={favorecidoRestaurante}
                                onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                    setFavorecidoRestaurante(newValue);
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.col}>
                            <label>CPF/CNPJ</label>
                            <InputMask
                                mask={cpfcnpjFavorecido.replace(/\D/g, '').length > 11 ? '99.999.999/9999-99' : '999.999.999-99'}
                                value={cpfcnpjFavorecido}
                                onChange={(e) => setCpfcnpjFavorecido(e.target.value)}
                            >
                                {(inputProps) => <Input {...inputProps} style={{ width: '100%' }} />}
                            </InputMask>

                        </div>
                    </div>
                    <div className={styles.row}>

                        <div className={styles.col}>
                            <label>Banco</label>
                            <Select className={styles.select} value={banco} placeholder='Selecione...' onChange={(value) => setBanco(value)} style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {bancoList.map((banco) => (
                                    <Select.Option value={banco.fullName} key={banco.fullName}>
                                        {banco.fullName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className={styles.col}>
                            <label>Tipo</label>
                            <Select className={styles.select} value={tipo} placeholder='Selecione...' onChange={(value) => setTipo(value)} style={{ width: '100%' }}>
                                {tipoList.map((tipo) => (
                                    <Select.Option key={tipo.value} value={tipo.value}>
                                        {tipo.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {tipo === 'pix' ? (
                        <div className={styles.col}>
                            <label>Chave PIX</label>
                            <Input
                                value={chavePix}
                                onChange={(e) => setChavePix(e.target.value)}
                                style={{ width: '100%' }}
                                placeholder='Digite a chave PIX...'
                            />
                        </div>
                    ) : (
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <label>Agência</label>
                                <Input
                                    value={agencia}
                                    onChange={(e) => setAgencia(e.target.value)}
                                    style={{ width: '100%' }}
                                    type='text' // Usar 'text' para permitir mais flexibilidade
                                    placeholder='Digite a agência...'
                                />
                            </div>
                            <div className={styles.col}>
                                <label>Dígito</label>
                                <Input
                                    value={digito}
                                    onChange={(e) => setDigito(e.target.value)}
                                    style={{ width: '100%' }}
                                    type='text' // Usar 'text' para permitir mais flexibilidade
                                    placeholder='Digite o dígito...'
                                />
                            </div>
                            <div className={styles.col}>
                                <label>Conta</label>
                                <Input
                                    value={conta}
                                    onChange={(e) => setConta(e.target.value)}
                                    style={{ width: '100%' }}
                                    type='text' // Usar 'text' para permitir mais flexibilidade
                                    placeholder='Digite a conta...'
                                />
                            </div>
                        </div>
                    )}
                </section>



            </div>
        </Modal>
    );
};

export default RestauranteModal;
