import React, { useState, useEffect } from 'react';
import { Breadcrumb, Table, Tooltip, Button, Modal, Input, Select, TimePicker, message } from 'antd';
import { Link } from 'react-router-dom';
import { AiTwotoneEdit } from 'react-icons/ai';
import { IoAdd } from 'react-icons/io5';
import Filtro from '../../Components/Filtro';
import styles from './style.module.scss';
import moment from 'moment';
import { api } from '../../services/apiClient';
import { useAuth } from '../../contexts/AuthContext';
import TimeInput from '../../Components/TimeInput';

const Notificacao = () => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Adicionar Notificação');
  const [loading, setLoading] = useState(false);
  const [tipo, setTipo] = useState(null);
  const [titulo, setTitulo] = useState('');
  const [horario, setHorario] = useState(null);
  const [texto, setTexto] = useState('');
  const [modalAction, setModalAction] = useState('Adicionar');
  const [data, setData] = useState([]);

  const [userMap, setUserMap] = useState({});
  const [restaurantMap, setRestaurantMap] = useState({});
  const [editRecordId, setEditRecordId] = useState(null);
  const [restauranteId, setRestauranteId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [restaurantOptions, setRestaurantOptions] = useState([]);

  const handleTimeChange = (e) => {
    setHorario(e.target.value);
  };

  useEffect(() => {
    setUserId(user.id);
    fetchDataNotificacao();
    fetchDataRestaurante();
  }, []);

  const fetchDataNotificacao = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/notificacoes');
      const notifications = response.data.message;

      const userIds = notifications.map((notif) => notif.userId).filter(Boolean);
      const restaurantIds = notifications.map((notif) => notif.restauranteId).filter(Boolean);

      await Promise.all([fetchUsers(userIds), fetchRestaurants(restaurantIds)]);

      setData(notifications);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar as notificações!');
    }
    setLoading(false);
  };

  const fetchUsers = async (userIds) => {
    try {
      const response = await api.get('/admin/usuarios', { ids: userIds });
      const users = response.data;
      const userMap = {};
      users.forEach((user) => {
        userMap[user.id] = `${user.nome} ${user.sobrenome}`;
      });
      setUserMap(userMap);
    } catch (error) {
      console.error('Erro ao buscar dados de usuários:', error);
    }
  };

  const fetchRestaurants = async (restaurantIds) => {
    try {
      const response = await api.get('/admin/restaurantes', { ids: restaurantIds });
      const restaurants = response.data.data.message;
      const restaurantMap = {};
      restaurants.forEach((restaurant) => {
        restaurantMap[restaurant.id] = restaurant.nome;
      });
      setRestaurantMap(restaurantMap);
    } catch (error) {
      console.error('Erro ao buscar dados de restaurantes:', error);
    }
  };

  const fetchDataRestaurante = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/restaurantes');
      const options = response.data.message.map((restaurante) => ({
        value: restaurante.id,
        label: restaurante.nome,
      }));
      setRestaurantOptions(options);
    } catch (error) {
      console.error('Erro ao buscar dados de restaurantes:', error);
      message.error('Erro ao buscar restaurantes!');
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setModalTitle('Editar Notificação');
    setModalAction('Salvar');
    setTitulo(record.titulo);
    setTipo(record.tipo);
    setEditRecordId(record.id);
    setTexto(record.texto);
    setHorario(record.horario ? moment(record.horario, 'HH:mm') : null);
    setUserId(record.userId || null);
    setRestauranteId(record.restauranteId || null);
    showLoading(true);
  };

  const showLoading = () => {
    setOpen(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleFilter = (filters) => {
    console.log('Filtrando com:', filters);
  };

  const tipoOptions = [];

  const handleFormSubmit = async () => {
    if (!titulo || !texto) {
      message.error('Por favor, preencha todos os campos!');
      return;
    }

    setLoading(true);

    try {
      const dadosNotificacao = {
        title: titulo,
        body: texto,
      };
      // 🔔 Enviar requisição para notificações gerais
      await api.post('/notificacao/geral', {
        title: titulo,
        body: texto,
      });

      message.success('Notificação enviada com sucesso!');

      fetchDataNotificacao();
      setOpen(false); // Fecha o modal após a conclusão da operação
    } catch (error) {
      console.error('Erro ao cadastrar/atualizar ou enviar notificação:', error);
      message.error('Erro ao cadastrar/atualizar ou enviar notificação!');
    }

    setLoading(false);
  };

  const handleSelectChange = (value) => {
    if (value === 'global') {
      setUserId(user.id);
      setRestauranteId(null);
    } else {
      setRestauranteId(value);
      setUserId(null);
    }
    setTipo(value);
  };

  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (text) => <a>{text}</a>,
      width: 150,
    },
    {
      title: 'Texto',
      dataIndex: 'texto',
      key: 'texto',
      width: 80,
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 80,
      render: (_, record) => {
        if (record.userId) {
          return userMap[record.userId] || 'Usuário Desconhecido';
        }
        if (record.restauranteId) {
          return restaurantMap[record.restauranteId] || 'Restaurante Desconhecido';
        }
        return 'Indefinido';
      },
    },
    {
      title: 'Horário',
      dataIndex: 'horario',
      key: 'horario',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => <Button type='tertiary' icon={<AiTwotoneEdit />} size={10} onClick={() => handleEdit(record)} />,
    },
  ];

  const filtroOptions = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'encerrado', label: 'Encerrado' },
    { value: 'pausado', label: 'Pausado' },
    // Adicione outras opções conforme necessário
  ];

  const statusOption = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'inativo', label: 'Inativo' },
    { value: 'deletado', label: 'Deletado' },
    // Adicione outras opções conforme necessário
  ];

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: <a>Gerenciamento</a>,
          },
          {
            title: (
              <>
                <span>Notificação</span>
              </>
            ),
          },
        ]}
      />

      <Button type='secondary' shape='round' icon={<IoAdd />} size='large' onClick={showLoading}>
        Notificação
      </Button>

      <Filtro onFilter={handleFilter} options={filtroOptions} showUsuarioInput={true} showTipoSelect={true} showButtonFiltrar={true} />

      <Table columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />

      <Modal
        title={<p>{modalTitle}</p>}
        footer={
          <div>
            <Button type='primary' onClick={handleFormSubmit}>
              {modalAction}
            </Button>
            <Button type='secondary' onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          </div>
        }
        loading={loading}
        open={open}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <div className={styles.containerModal}>
          <div>
            <label>Titulo</label>
            <Input value={titulo} placeholder='Desconto Dia dos Namorados' onChange={(e) => setTitulo(e.target.value)} style={{ width: '100%' }} />
          </div>
          <div>
            <label>Texto</label>
            <Input value={texto} placeholder='Venha comemorar com seu amor' onChange={(e) => setTexto(e.target.value)} style={{ width: '100%' }} />
          </div>
          <div>
            <label>
              Horário <strong>(hora que vai ser enviada a notificação)</strong>
            </label>
            <TimeInput value={horario} onChange={handleTimeChange} />
          </div>
          <div>
            <label>Tipo</label>
            <Select className={styles.select} value={tipo} placeholder='Selecione...' onChange={handleSelectChange} style={{ width: '100%' }}>
              <Select.Option value='global'>Global</Select.Option>
              {restaurantOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Notificacao;
