import React from 'react';
import { Table, Button, Col, Popconfirm, message } from 'antd';
import { GoVerified } from "react-icons/go";
import moment from 'moment';
import 'moment/locale/pt-br';

const mapStatus = (status) => {
  switch (status) {
    case 'pendente':
      return 'Pendente';
    case 'aguardando':
      return 'Aguardando';
    case 'preparando':
      return 'Preparando';
    default:
      return 'Desconhecido';
  }
};

const mapTipo = (tipo) => {
  switch (tipo) {
    case 'retirada':
      return 'Retirada';
    case 'comerNoLocal':
      return 'Comer no local';
    default:
      return 'Desconhecido';
  }
};



// Função para calcular o valor total
const calcularValorTotal = (pedidosItens) => {
  return pedidosItens.reduce((total, item) => total + item.quantidade * item.valor, 0).toFixed(2);
};

const CheckinsRestauranteTable = ({ data, loading, handleConfirmCheckin }) => {
  const columns = [
    {
      title: 'Usuário',
      dataIndex: 'userName', // You can still use this or adjust if necessary
      key: 'userName',
      render: (text, record) => (
        <div>
          <a>{record.usuarios.nome}</a>
          <div style={{ fontSize: '12px' }}>{record.usuarios.email}</div>
        </div>
      ),
      width: 150,
    },    
    {
      title: 'Produto(s)',
      dataIndex: 'pedidosItens',
      key: 'pedidosItens',
      render: (pedidosItens) => (
        <div>
          {pedidosItens.map((item) => (
            <div key={item.id}>
              <a>{item.produto.titulo}</a>
              <div style={{ fontSize: '12px' }}>{item.produto.descricao}</div>
              <div>Quantidade: {item.quantidade}</div>
            </div>
          ))}
        </div>
      ),
      width: 200,
    },
    {
      title: 'Preparar',
      dataIndex: 'diaPreparo',
      key: 'diaPreparo',
      render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (tipo) => mapTipo(tipo),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => mapStatus(status),
    },
    {
      title: 'Valor Total',
      dataIndex: 'pedidosItens',
      key: 'valorTotal',
      render: (pedidosItens) => `R$ ${calcularValorTotal(pedidosItens)}`,
    },
    {
      title: 'Data do pedido',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      ellipsis: {
        showTitle: false,
      },
      render: (address, record) => (
        <Col>
          <Popconfirm
            title="Tem certeza que deseja aceitar este checkin?"
            onConfirm={() => handleConfirmCheckin(record.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              type="primary"
              shape="circle"
              icon={<GoVerified />}
              size={10}
            />
          </Popconfirm>
        </Col>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      scroll={{ x: 'max-content' }}
      loading={loading}
    />
  );
};

export default CheckinsRestauranteTable;