import React, { useState } from 'react';
import styles from './style.module.scss';
import { message } from 'antd';
import { toast } from 'react-toastify';
import logo from '../../assets/logo.png';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import PrintComponent from '../../Components/PrintComponent'; // Importa o componente de impressão

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user || !password) {
      message.warning('Por favor, preencha todos os campos.');
      return;
    }
    setLoading(true);
    try {
      const logged = await login(user, password);
      if (logged) {
        message.success('Login efetuado com sucesso.');
        window.location.href = '/';
      } else {
        message.warning('Falha ao fazer login, confirme seus dados');
      }
    } catch (error) {
      message.error('Erro ao fazer login!');
      console.log('error.message', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <div className={styles.logo}>
          <img src={logo} alt='logo' />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor='user'>E-mail</label>
            <div className={styles.inputWithIcon}>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <path d='M0.833267 2.5H19.1666V17.5H0.833267V2.5ZM2.49993 4.16667V5.39945L9.99993 10.6495L17.4999 5.39945V4.16667H2.49993ZM17.4999 7.43388L9.99993 12.6839L2.49993 7.43388V15.8333H17.4999V7.43388Z' fill='#99A0A8' />
              </svg>
              <input type='text' id='user' placeholder='Digite seu usuario' value={user} onChange={handleUserChange} />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor='password'>Senha</label>
            <div className={styles.inputWithIcon}>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <path d='M10.8333 11.6667V15H9.16667V11.6667H10.8333Z' fill='#99A0A8' />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M15 7.5V5.83333C15 3.07191 12.7614 0.833332 10 0.833332C7.23858 0.833332 5.00001 3.07191 5.00001 5.83333V7.5H1.66667V19.1667H18.3333V7.5H15ZM10 2.5C8.15906 2.5 6.66667 3.99238 6.66667 5.83333V7.5H13.3333V5.83333C13.3333 3.99238 11.841 2.5 10 2.5ZM3.33334 9.16666V17.5H16.6667V9.16666H3.33334Z'
                  fill='#99A0A8'
                />
              </svg>
              <input type={showPassword ? 'text' : 'password'} id='password' placeholder='Digite sua senha' value={password} onChange={handlePasswordChange} />
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' onClick={togglePasswordVisibility}>
                <path
                  d='M6.54622 10C6.54622 8.12268 8.12954 6.66667 9.9995 6.66667C11.8695 6.66667 13.4528 8.12268 13.4528 10C13.4528 11.8773 11.8695 13.3333 9.9995 13.3333C8.12954 13.3333 6.54622 11.8773 6.54622 10ZM9.9995 8.33333C8.97555 8.33333 8.21289 9.11589 8.21289 10C8.21289 10.8841 8.97555 11.6667 9.9995 11.6667C11.0234 11.6667 11.7861 10.8841 11.7861 10C11.7861 9.11589 11.0234 8.33333 9.9995 8.33333Z'
                  fill='#99A0A8'
                />
                <path
                  d='M0.875111 9.73943C2.10384 6.00645 5.74069 3.33333 9.99999 3.33333C14.2593 3.33333 17.8962 6.00648 19.1249 9.73949L19.2106 10L19.1249 10.2606C17.8962 13.9936 14.2593 16.6667 10 16.6667C5.74069 16.6667 2.10382 13.9935 0.875109 10.2605L0.789352 9.99997L0.875111 9.73943ZM2.54959 9.99997C3.62807 12.8954 6.54145 15 10 15C13.4585 15 16.3719 12.8954 17.4504 10C16.3719 7.10459 13.4585 5 9.99999 5C6.54145 5 3.62808 7.10457 2.54959 9.99997Z'
                  fill='#99A0A8'
                />
              </svg>
            </div>
          </div>
          <button type='submit' className={styles.button} disabled={loading}>
            {loading ? (
              <div className={styles.loading}></div>
            ) : (
              <>
                Entrar
                <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                  <path
                    d='M18 3.125C18 2.95924 17.9342 2.80027 17.8169 2.68306C17.6997 2.56585 17.5408 2.5 17.375 2.5H9.875C9.70924 2.5 9.55027 2.56585 9.43306 2.68306C9.31585 2.80027 9.25 2.95924 9.25 3.125C9.25 3.29076 9.31585 3.44973 9.43306 3.56694C9.55027 3.68415 9.70924 3.75 9.875 3.75H15.8662L3.1825 16.4325C3.12439 16.4906 3.07829 16.5596 3.04685 16.6355C3.0154 16.7114 2.99921 16.7928 2.99921 16.875C2.99921 16.9572 3.0154 17.0386 3.04685 17.1145C3.07829 17.1904 3.12439 17.2594 3.1825 17.3175C3.24061 17.3756 3.3096 17.4217 3.38552 17.4532C3.46144 17.4846 3.54282 17.5008 3.625 17.5008C3.70718 17.5008 3.78855 17.4846 3.86448 17.4532C3.9404 17.4217 4.00939 17.3756 4.0675 17.3175L16.75 4.63375V10.625C16.75 10.7908 16.8158 10.9497 16.9331 11.0669C17.0503 11.1842 17.2092 11.25 17.375 11.25C17.5408 11.25 17.6997 11.1842 17.8169 11.0669C17.9342 10.9497 18 10.7908 18 10.625V3.125Z'
                    fill='white'
                  />
                </svg>
              </>
            )}
          </button>
        </form>

      </div>
    </div>
  );
};

export default Login;
