import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Select, Upload, Image, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import CategoriaModal from '../../Components/CategoriaModal';
import { AiOutlineDelete } from 'react-icons/ai';

const { Option } = Select;
const urlS3 = 'https://rangopassbucket.s3.amazonaws.com/';

const ProdutoModal = ({ open, setOpen, modalTitle, modalAction, selectedProduct, loading, setLoading, previewImage, setPreviewImage, previewOpen, setPreviewOpen, editRecordId, fecthDataProduto }) => {
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tempoPreparacao, setTempoPreparacao] = useState('');
  const [valor, setValor] = useState('');
  const [sku, setSku] = useState('');
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [status, setStatus] = useState(null);
  const [restaurante, setRestaurante] = useState(null);
  const [restauranteId, setRestauranteId] = useState(null);
  const [fileListPrincipal, setFileListPrincipal] = useState([]);
  const [fileListFotos, setFileListFotos] = useState([]);
  const [fileListVideos, setFileListVideos] = useState([]);
  const [imageUrlsPrincipal, setImageUrlsPrincipal] = useState([]);
  const [imageUrlsFotos, setImageUrlsFotos] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState('');
  const [imagemFotos, setImagemFotos] = useState('');
  const [video, setVideo] = useState('');
  const [categoriaId, setCategoriaId] = useState('');
  const [categoriaList, setCategoriaList] = useState([]);
  const [modalActionCategoria, setModalActionCategoria] = useState('Adicionar');
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [categoriaModalVisible, setCategoriaModalVisible] = useState(false);
  const [adicionais, setAdicionais] = useState([{ id: null, titulo: '', valor: '' }]);
  const [adicionalModalOpen, setAdicionalModalOpen] = useState(false);
  const [data, setData] = useState([]);

  // Função para resetar todos os campos do formulário
  const resetForm = () => {
    setTitulo('');
    setDescricao('');
    setTempoPreparacao('');
    setValor('');
    setSku('');
    setStatus(null);
    setRestauranteId(null);
    setCategoriaId('');
    setFileListPrincipal([]);
    setFileListFotos([]);
    setFileListVideos([]);
    setAdicionais([{ id: null, titulo: '', valor: '' }]);
  };

  // Efeito para resetar o formulário quando o modal é aberto
  useEffect(() => {
    if (open) {
      resetForm();
      if (selectedProduct) {
        // Carrega os dados do produto selecionado se estiver em modo de edição
        setTitulo(selectedProduct.titulo || '');
        setDescricao(selectedProduct.descricao || '');
        setTempoPreparacao(selectedProduct.tempoPreparacao || '');
        setValor(selectedProduct.valor ? selectedProduct.valor.toFixed(2).replace('.', ',') : '');
        setSku(selectedProduct.sku || '');
        setStatus(selectedProduct.status || null);
        setRestauranteId(selectedProduct.restaurante || null);
        setCategoriaId(selectedProduct.categorias?.id || '');
      }
    }
  }, [open, selectedProduct]);

  // Manipula o fechamento do modal
  // Manipula o fechamento do modal
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  // URL base para imagens

  useEffect(() => {
    fetchDataRestaurante();
    fetchDataCategoria();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      // Preenche os campos com os dados do produto selecionado
      setTitulo(selectedProduct.titulo || '');
      setDescricao(selectedProduct.descricao || '');
      setTempoPreparacao(selectedProduct.tempoPreparacao || '');
      setValor(selectedProduct.valor ? selectedProduct.valor.toFixed(2).replace('.', ',') : '');
      setSku(selectedProduct.sku || '');
      setStatus(selectedProduct.status || null);
      setRestauranteId(selectedProduct.restaurante || null);
      setRestaurante(selectedProduct.restaurante?.nome || null);
      setCategoriaId(selectedProduct.categorias?.id || '');

      // Preenche os adicionais
      setAdicionais(
        selectedProduct.adicionais?.map((adicional) => ({
          id: adicional.id || null,
          titulo: adicional.titulo,
          valor: adicional.valor,
          desativado: adicional.desativado || false,
        })) || [{ id: null, titulo: '', valor: '' }]
      );

      // Preenche a imagem principal
      const imagemPrincipalObj = selectedProduct.arquivos?.find((arquivo) => arquivo.tipo === 'imagem_principal');
      if (imagemPrincipalObj) {
        setImagemPrincipal(imagemPrincipalObj.key);
        setFileListPrincipal([
          {
            uid: imagemPrincipalObj.id,
            name: imagemPrincipalObj.key,
            status: 'done',
            url: `${urlS3}${imagemPrincipalObj.key}`,
          },
        ]);
        setImageUrlsPrincipal([`${urlS3}${imagemPrincipalObj.key}`]);
      } else {
        setFileListPrincipal([]);
        setImagemPrincipal('');
        setImageUrlsPrincipal([]);
      }

      // Preenche as fotos (se houver)
      const imagensFotos = selectedProduct.arquivos?.filter((arquivo) => arquivo.tipo === 'imagem_foto') || [];
      setFileListFotos(
        imagensFotos.map((arquivo) => ({
          uid: arquivo.id,
          name: arquivo.key,
          status: 'done',
          url: `${urlS3}${arquivo.key}`,
        }))
      );
      setImageUrlsFotos(imagensFotos.map((arquivo) => `${urlS3}${arquivo.key}`));

      // Preenche os vídeos (se houver)
      const videos = selectedProduct.arquivos?.filter((arquivo) => arquivo.tipo === 'video') || [];
      setFileListVideos(
        videos.map((arquivo) => ({
          uid: arquivo.id,
          name: arquivo.key,
          status: 'done',
          url: `${urlS3}${arquivo.key}`,
        }))
      );
      setVideoUrls(videos.map((arquivo) => `${urlS3}${arquivo.key}`));
    } else {
      // Reseta os campos quando não há produto selecionado
      setTitulo('');
      setDescricao('');
      setTempoPreparacao('');
      setValor('');
      setSku('');
      setStatus(null);
      setRestauranteId(null);
      setRestaurante(null);
      setCategoriaId('');
      setAdicionais([{ id: null, titulo: '', valor: '' }]);
      setFileListPrincipal([]);
      setImagemPrincipal('');
      setImageUrlsPrincipal([]);
      setFileListFotos([]);
      setImagemFotos('');
      setImageUrlsFotos([]);
      setFileListVideos([]);
      setVideo('');
      setVideoUrls([]);
    }
  }, [selectedProduct]);

  const handleFormSubmitCategoria = () => {
    fetchDataCategoria();
    setCategoriaModalVisible(false);
  };

  const openAddCategoriaModal = () => {
    setModalActionCategoria('Adicionar');
    setSelectedCategoria(null);
    setCategoriaModalVisible(true);
  };

  const handleSubmitAndFormSubmit = async () => {
    // Validação básica
    if (!titulo || !tempoPreparacao || !status || !valor) {
      message.error('Por favor, preencha todos os campos obrigatórios!');
      return;
    }

    // No modo de edição, permite que a imagem principal já existente seja mantida
    if (modalAction === 'Cadastrar' && imageUrlsPrincipal.length === 0) {
      message.error('Por favor, escolha uma imagem principal!');
      return;
    }

    // Formatação do valor
    const formatCurrencyToNumber = (value) => {
      if (!value) return 0;
      // Remove "R$", espaços e substitui vírgula por ponto para decimais
      return parseFloat(value.replace(/[R$\s]/g, '').replace(',', '.'));
    };

    setLoading(true);

    try {
      // Upload da imagem principal (se houver novos arquivos)
      let imgPrincipalKey = imagemPrincipal;
      if (fileListPrincipal.some((file) => !file.url && !file.status === 'done')) {
        const uploadPromises = fileListPrincipal.map((file) => {
          if (!file.url && file.originFileObj) {
            return handleUploadPrincipal({ file: file.originFileObj });
          }
          return Promise.resolve(file.url ? file.url.replace(urlS3, '') : null);
        });
        const uploadedKeys = await Promise.all(uploadPromises);
        imgPrincipalKey = uploadedKeys.find((key) => key) || imagemPrincipal;
      }

      // Upload das fotos (se houver novos arquivos)
      let imgFotosKeys = imagemFotos;
      if (fileListFotos.some((file) => !file.url && file.originFileObj)) {
        const uploadFotosPromises = fileListFotos.map((file) => {
          if (!file.url && file.originFileObj) {
            return handleUploadFotos({ file: file.originFileObj });
          }
          return Promise.resolve(file.url ? file.url.replace(urlS3, '') : null);
        });
        const uploadedFotosKeys = await Promise.all(uploadFotosPromises);
        imgFotosKeys = uploadedFotosKeys.filter((key) => key); // Filtra apenas chaves válidas
      }

      // Upload dos vídeos (se houver novos arquivos)
      let videoKey = video;
      if (fileListVideos.some((file) => !file.url && file.originFileObj)) {
        const uploadVideoPromises = fileListVideos.map((file) => {
          if (!file.url && file.originFileObj) {
            return handleUploadVideo({ file: file.originFileObj });
          }
          return Promise.resolve(file.url ? file.url.replace(urlS3, '') : null);
        });
        const uploadedVideoKeys = await Promise.all(uploadVideoPromises);
        videoKey = uploadedVideoKeys.find((key) => key) || video;
      }

      // Conversão do valor para número
      const valorNumerico = formatCurrencyToNumber(valor);

      const authToken = localStorage.getItem('RangoPassToken');
      if (!authToken) {
        throw new Error('Token de autenticação não encontrado.');
      }

      const dadosProdutos = {
        restauranteId,
        categoriaId,
        titulo,
        descricao,
        tempoPreparacao: Number(tempoPreparacao),
        status,
        valor: valorNumerico,
        sku,
        imgPrincipal: imgPrincipalKey, // Chave da imagem principal
        imgInterna: imgFotosKeys, // Array de chaves das fotos internas
        video: videoKey, // Chave do vídeo
        adicionais: adicionais.map((adicional) => ({
          id: adicional.id || null,
          titulo: adicional.titulo,
          valor: adicional.valor,
          desativado: adicional.desativado || false,
        })),
      };
      console.log('restauranteId', restauranteId);
      console.log('dadosProdutos', dadosProdutos);

      let response;

      if (modalAction === 'Cadastrar') {
        response = await api.post('/admin/produtos', dadosProdutos, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('response', response.data.message);
        message.success('Produto cadastrado com sucesso!');
      } else if (modalAction === 'Salvar') {
        response = await api.put(`/admin/produtos/${editRecordId}`, dadosProdutos, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('response', response.data.message);
        message.success('Produto atualizado com sucesso!');
      }

      setOpen(false);
      fecthDataProduto();
    } catch (error) {
      console.error(error.message);
      message.error('Erro ao cadastrar/atualizar produto!');
    }

    setLoading(false);
  };

  const fetchDataRestaurante = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/restaurantes');
      const options = response.data.message.map((restaurante) => ({
        value: restaurante.id,
        label: restaurante.nome,
      }));
      setRestaurantOptions(options);
    } catch (error) {
      console.error('Erro ao buscar dados de restaurantes:', error);
      message.error('Erro ao buscar restaurantes!');
    }
    setLoading(false);
  };

  const fetchDataCategoria = async () => {
    setLoading(true);
    try {
      const response = await api.get('/categorias');
      console.log('categoria', response);

      const filteredCategorias = response.data.message.filter((categoria) => categoria.tipo === 'produto');

      setCategoriaList(filteredCategorias);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar as categorias!');
    } finally {
      setLoading(false);
    }
  };

  const uploadButton = (label) => (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <div>{label}</div>
    </button>
  );

  const statusOptions = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'inativo', label: 'Inativo' },
  ];

  const onPreviewPrincipal = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onPreviewFotos = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onPreviewVideo = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const videoElement = `<video width="600" controls>
                                <source src="${src}" type="video/mp4">
                                Seu navegador não suporta o elemento de vídeo.
                              </video>`;
    const videoWindow = window.open('', '_blank');
    videoWindow?.document.write(videoElement);
  };

  const onChangePrincipal = ({ fileList: newFileList }) => {
    const validFileList = newFileList.filter((file) => (file.originFileObj ? isFileSizeValid(file.originFileObj) : true));
    setFileListPrincipal(validFileList);
    const urls = validFileList.map((file) => file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''));
    setImageUrlsPrincipal(urls);
  };

  const onChangeFotos = ({ fileList: newFileList }) => {
    const validFileList = newFileList.filter((file) => (file.originFileObj ? isFileSizeValid(file.originFileObj) : true));
    setFileListFotos(validFileList);
    const urlsFotos = validFileList.map((file) => file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''));
    setImageUrlsFotos(urlsFotos);
  };

  const onChangeVideo = ({ fileList: newFileList }) => {
    setFileListVideos(newFileList);
    const urlsVideo = newFileList.map((file) => file.url || (file.originFileObj ? URL.createObjectURL(file.originFileObj) : ''));
    setVideoUrls(urlsVideo);
    console.log('urls', urlsVideo);
  };

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

  const isFileSizeValid = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };

  const handleUploadPrincipal = async ({ file, onSuccess, onError }) => {
    if (!isFileSizeValid(file)) {
      message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
      if (onError) {
        onError(new Error('O arquivo é muito grande.'));
      }
      return;
    }

    const formData = new FormData();
    formData.append('media', file);
    formData.append('type', 'imagem');

    try {
      const response = await api.post('/upload-media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onSuccess) {
        onSuccess(response.data.message.key);
        setImagemPrincipal(response.data.message.key);
      }
      return response.data.message.key;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.error('Erro ao fazer upload da imagem:', error.message);
    }
  };

  const handleUploadFotos = async ({ file, onSuccess, onError }) => {
    if (!isFileSizeValid(file)) {
      message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
      if (onError) {
        onError(new Error('O arquivo é muito grande.'));
      }
      return;
    }

    const formData = new FormData();
    formData.append('media', file);
    formData.append('type', 'imagem');

    try {
      const response = await api.post('/upload-media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onSuccess) {
        onSuccess(response.data.message.key);
        setImagemFotos((prev) => (prev ? [...prev, response.data.message.key] : [response.data.message.key]));
      }
      return response.data.message.key;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.error('Erro ao fazer upload da imagem:', error.message);
    }
  };

  const handleUploadVideo = async ({ file, onSuccess, onError }) => {
    if (!isFileSizeValid(file)) {
      message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
      if (onError) {
        onError(new Error('O arquivo é muito grande.'));
      }
      return;
    }

    const formData = new FormData();
    formData.append('media', file);
    formData.append('type', 'video');

    try {
      const response = await api.post('/upload-media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onSuccess) {
        onSuccess(response.data.message.key);
        setVideo(response.data.message.key);
      }
      return response.data.message.key;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.error('Erro ao fazer upload do vídeo:', error.message);
    }
  };

  const handleSaveAdicional = () => {
    setAdicionalModalOpen(false);
  };

  const handleAddRow = () => {
    setAdicionais([...adicionais, { id: null, titulo: '', valor: '' }]);
  };

  const handleAdicionalChange = (index, field, value) => {
    const newAdicionais = [...adicionais];
    newAdicionais[index][field] = value;
    setAdicionais(newAdicionais);
  };

  const handleDeleteRow = (index) => {
    const newAdicionais = adicionais.map((adicional, i) => (i === index ? { ...adicional, desativado: true } : adicional));
    setAdicionais(newAdicionais);
    console.log('newAdicionais', newAdicionais);
  };

  const formatCurrency = (value) => {
    if (!value) return '';
    const number = value
      .replace(/[R$\s]/g, '')
      .replace('.', '')
      .replace(',', '.');
    const floatValue = parseFloat(number);
    if (isNaN(floatValue)) return '';
    return floatValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const handleChangeValor = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatCurrency(inputValue);
    setValor(formattedValue);
  };

  return (
    <div>
      <Modal
        title={<p>{modalTitle}</p>}
        footer={
          <div className={styles.buttons}>
            <Button type='primary' onClick={() => setAdicionalModalOpen(true)}>
              Adicionais
            </Button>
            <Button type='default' onClick={() => handleClose(false)}>
              Cancelar
            </Button>
            <Button type='primary' onClick={handleSubmitAndFormSubmit} loading={loading}>
              {modalAction}
            </Button>
          </div>
        }
        visible={open}
        onCancel={() => handleClose(false)}
        confirmLoading={loading}
      >
        <div className={styles.containerModal}>
          {/* Upload da Imagem Principal */}
          <div className={styles.containerUpload}>
            <ImgCrop rotationSlider>
              <Upload
                listType='picture-card'
                fileList={fileListPrincipal}
                onChange={onChangePrincipal}
                onPreview={onPreviewPrincipal}
                customRequest={handleUploadPrincipal}
                beforeUpload={(file) => {
                  if (!isFileSizeValid(file)) {
                    message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
                    return Upload.LIST_IGNORE;
                  }
                  return true;
                }}
              >
                {fileListPrincipal.length < 1 && 'Foto Principal'}
              </Upload>
            </ImgCrop>
          </div>

          {/* Campos do Formulário */}
          <div className={styles.containerModal}>
            <div>
              <label>Restaurante</label>
              <Select
                className={styles.select}
                value={restauranteId}
                placeholder='Selecione...'
                onChange={(value) => {
                  const selected = restaurantOptions.find((option) => option.value === value);
                  setRestaurante(value);
                  setRestauranteId(value);
                }}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                {restaurantOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <label>Produto</label>
              <Input value={titulo} onChange={(e) => setTitulo(e.target.value)} style={{ width: '100%' }} />
            </div>
            <div>
              <label>Descrição</label>
              <Input value={descricao} onChange={(e) => setDescricao(e.target.value)} style={{ width: '100%' }} />
            </div>
            <div className={styles.row}>
              <div>
                <label>Tempo Preparo</label>
                <Input type='number' value={tempoPreparacao} onChange={(e) => setTempoPreparacao(e.target.value)} style={{ width: '100%' }} />
              </div>
              <div>
                <label>Valor</label>
                <Input type='text' value={valor} onChange={handleChangeValor} style={{ width: '100%' }} />
              </div>
            </div>
            <div>
              <label>Código SKU</label>
              <Input value={sku} onChange={(e) => setSku(e.target.value)} style={{ width: '100%' }} />
            </div>
            <div className={styles.col}>
              <label>Categoria</label>
              <Select
                className={styles.select}
                value={categoriaId}
                placeholder='Selecione...'
                onChange={(value) => setCategoriaId(value)}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                {categoriaList.map((categoria) => (
                  <Option key={categoria.id} value={categoria.id}>
                    {categoria.titulo}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Button onClick={openAddCategoriaModal}>+ Categoria</Button>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <label>Status</label>
                <Select className={styles.select} value={status} placeholder='Selecione...' onChange={(value) => setStatus(value)} style={{ width: '100%' }}>
                  {statusOptions.map((statusOption) => (
                    <Option key={statusOption.value} value={statusOption.value}>
                      {statusOption.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* Upload de Fotos Internas */}
            <div className={styles.containerUpload}>
              <ImgCrop rotationSlider>
                <Upload
                  listType='picture-card'
                  fileList={fileListFotos}
                  onChange={onChangeFotos}
                  onPreview={onPreviewFotos}
                  customRequest={handleUploadFotos}
                  beforeUpload={(file) => {
                    if (!isFileSizeValid(file)) {
                      message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
                      return Upload.LIST_IGNORE;
                    }
                    return true;
                  }}
                >
                  {fileListFotos.length < 3 && 'Fotos'}
                </Upload>
              </ImgCrop>
              {/* Upload de Vídeos */}
              <Upload
                listType='picture-card'
                fileList={fileListVideos}
                onPreview={onPreviewVideo}
                onChange={onChangeVideo}
                customRequest={handleUploadVideo}
                beforeUpload={(file) => {
                  if (!isFileSizeValid(file)) {
                    message.error('O arquivo é muito grande. O tamanho máximo permitido é 2 MB.');
                    return Upload.LIST_IGNORE;
                  }
                  return true;
                }}
              >
                {fileListVideos.length >= 1 ? null : uploadButton('Vídeos')}
              </Upload>

              {/* Preview da Imagem Principal */}
              {previewImage && (
                <Image
                  wrapperStyle={{ display: 'none' }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal para Adicionar Categoria */}
      <CategoriaModal
        visible={categoriaModalVisible}
        onClose={() => setCategoriaModalVisible(false)}
        onAddCategoria={() => fetchDataCategoria()}
        onSubmit={handleFormSubmitCategoria}
        modalAction={modalActionCategoria}
        selectedCategoria={selectedCategoria}
        initialData={data.find((item) => item.id === editRecordId) || {}}
      />

      {/* Modal para Adicionais */}
      <Modal
        title={<p>Adicionais</p>}
        footer={
          <div className={styles.buttons}>
            <Button type='primary' onClick={handleAddRow}>
              Add
            </Button>
            <Button type='primary' onClick={handleSaveAdicional}>
              Salvar
            </Button>
            <Button type='default' onClick={() => setAdicionalModalOpen(false)}>
              Cancelar
            </Button>
          </div>
        }
        visible={adicionalModalOpen}
        onCancel={() => setAdicionalModalOpen(false)}
      >
        {adicionais
          .filter((adicional) => !adicional.desativado) // Filtra itens onde desativado é false ou não está presente
          .map((adicional, index) => (
            <div key={index} className={styles.adicionais}>
              <div className={styles.containerAdicionais}>
                <label>Título</label>
                <Input value={adicional.titulo} onChange={(e) => handleAdicionalChange(index, 'titulo', e.target.value)} style={{ marginRight: 8 }} />
              </div>
              <div className={styles.containerAdicionais}>
                <label>Valor</label>
                <Input type='number' value={adicional.valor} onChange={(e) => handleAdicionalChange(index, 'valor', e.target.value)} style={{ width: '100%' }} prefix='R$ ' />
              </div>
              <div className={styles.iconAdicionais}>
                <Button shape='circle' onClick={() => handleDeleteRow(index)}>
                  <AiOutlineDelete />
                </Button>
              </div>
            </div>
          ))}
      </Modal>
    </div>
  );
};
export default ProdutoModal;
