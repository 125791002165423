// src/Components/PedidosRestauranteTable.jsx
import React from 'react';
import { Table, Button, Col } from 'antd';
import { AiOutlineEye, AiOutlinePrinter } from 'react-icons/ai';
import moment from 'moment';
import 'moment/locale/pt-br';

const PedidosRestauranteTable = ({ data, handleEdit, handlePrint }) => {
  console.log('data', data);

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const formatarCodigoPedido = (codigo) => {
    const partes = codigo.split('-');
    return partes[partes.length - 1].toUpperCase();
  };

  const columns = [
    {
      title: 'Código',
      dataIndex: 'id',
      key: 'id',
      render: (codigoPedido) => formatarCodigoPedido(codigoPedido),
    },
    {
      title: 'Usuário',
      dataIndex: 'nomeUsuario',
      key: 'nomeUsuario',
      render: (text, record) => (
        <div>
          <a>{text}</a>
          <div style={{ fontSize: '12px' }}>{record.emailUsuario}</div>
        </div>
      ),
      width: 150,
    },
    {
      title: 'Produto',
      dataIndex: 'itens',
      key: 'itens',
      render: (itens) => (
        itens.map((item, index) => (
          <div key={index}>
            <a>{item.produto}</a>
            <div style={{ fontSize: '12px' }}>Qtd: {item.quantidade}, Valor: {formatarValor(item.valor)}</div>
          </div>
        ))
      ),
      width: 150,
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor',
      key: 'valor',
      render: (valor) => formatarValor(valor),
    },
    {
      title: 'Status',
      dataIndex: 'statusPedido',
      key: 'statusPedido',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Data do pedido',
      dataIndex: 'dataPedido',
      key: 'dataPedido',
      render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        console.log('record table', record);

        return (
          <Col>
            <Button
              type="primary"
              shape="circle"
              icon={<AiOutlineEye />}
              size={10}
              style={{ marginRight: '8px' }}
              onClick={() => handleEdit(record)}
            />
            <Button
              type="default"
              shape="circle"
              icon={<AiOutlinePrinter />}
              size={10}
              onClick={() => handlePrint(record)} // Chama handlePrint com os dados do pedido
            />
          </Col>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />;
};

export default PedidosRestauranteTable;
