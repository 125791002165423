// src/Components/PrintComponent.js
import React from 'react';

const PrintComponent = () => {
  const handlePrint = async () => {
    try {
      const result = await window.electron.print();
      alert(result); // Exibe o status da impressão
    } catch (error) {
      console.error('Erro ao imprimir:', error);
      alert('Erro ao enviar para impressão');
    }
  };

  return (
    <div>
      <button onClick={handlePrint}>Imprimir na Impressora Térmica</button>
    </div>
  );
};

export default PrintComponent;
