import React, { useState } from 'react';
import { Modal, Input, Button, message } from 'antd';
import styles from './style.module.scss';
import moment from 'moment';
import 'moment/locale/pt-br';
import { api } from '../../services/apiClient'; // Assegure-se de que a API está importada

const PedidoRestauranteModal = ({
  open,
  loading,
  modalTitle,
  codigoPedido,
  statusPedido,
  nomeUsuario,
  createdAt,
  itens, // Recebe a lista de itens do pedido
  valor,
  onCancel,
  onStatusUpdate, // Callback para informar o componente pai sobre a atualização
}) => {
  console.log('codigoPedido', codigoPedido)
  const [buttonLoading, setButtonLoading] = useState(false); // Estado para o botão de atualização

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const formatarData = (data) => {
    return moment(data).format('DD/MM/YY, HH:mm');
  };

  const valorFormatado = formatarValor(valor);
  const dataFormatada = formatarData(createdAt);

  // Função para pegar o último trecho do código após o hífen e deixar em maiúsculas
  const formatarCodigoPedido = (codigo) => {
    console.log('codigo', codigo)
    const partes = codigo?.split('-');
    return partes[partes.length - 1].toUpperCase(); // Pega o último trecho e deixa em maiúsculas
  };

  // Função para atualizar o status do pedido para "pronto"
  const marcarComopronto = async () => {
    setButtonLoading(true);
    try {
      const response = await api.put(`/pedido/editar/${codigoPedido}`, {
        status: 'pronto', // Certifique-se de que este status exista na sua API
      });
      message.success('Pedido marcado como impresso com sucesso!');
      setButtonLoading(false);
      onStatusUpdate && onStatusUpdate(); // Informa o componente pai sobre a atualização
    } catch (error) {
      console.error('Erro ao atualizar o status do pedido:', error);
      message.error('Erro ao marcar o pedido como impresso.');
      setButtonLoading(false);
    }
  };

  return (
    <Modal
      title={<p>{modalTitle}</p>}
      footer={[
        <Button key="close" onClick={onCancel}>
          Fechar
        </Button>,
        statusPedido !== 'pronto' && (
          <Button
            key="pronto"
            type="primary"
            loading={buttonLoading}
            onClick={marcarComopronto}
          >
            Marcar como pronto
          </Button>
        )
      ]}
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
    >
      <div className={styles.containerModal}>
        <span className={styles.codigo}>{formatarCodigoPedido(codigoPedido)}</span>
        <span className={`${styles.status} ${statusPedido === 'Finalizado' ? styles.statusAtivo : styles.statusPendente}`}>
          {statusPedido}
        </span>
        <div>
          <label>Nome</label>
          <Input value={nomeUsuario} disabled style={{ width: '100%' }} />
        </div>
        <div>
          <label>Criado em</label>
          <Input value={dataFormatada} disabled style={{ width: '100%' }} />
        </div>
        <div>
          <label>Valor Total</label>
          <Input value={valorFormatado} disabled style={{ width: '100%' }} />
        </div>
        {/* Mapeia os itens do pedido e exibe cada produto com seus adicionais */}
        <div>
          <label>Produtos</label>
          {itens.map((item, index) => (
            <div key={index} className={styles.produtoItem}>
              <p>{item.produto} - Quantidade: {item.quantidade}</p>
              
              {/* Verifica se existem adicionais */}
              {item.adicionais && item.adicionais.length > 0 && (
                <div className={styles.adicionais}>
                  <p><strong>Adicionais:</strong></p>
                  <ul>
                    {item.adicionais.map((adicional, idx) => (
                      <li key={idx}>
                        {adicional.titulo} - {formatarValor(adicional.valor)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PedidoRestauranteModal;
