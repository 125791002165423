import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { Breadcrumb, Card, message } from 'antd';
import { Link } from 'react-router-dom';
import { api } from '../../services/apiClient';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    saldoTotal: 0,
    totalCheckins: 0,
    totalClientes: 0,
    totalFaturamento: 0,
    totalIndicacoes: 0,
    totalPedidos: 0,
    totalRestaurantes: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/dashboard');
        console.log('response DAATA', response)
        setDashboardData(response.data.data);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        message.error('Erro ao buscar os dados do dashboard!');
      }
    };

    fetchData(); 
  }, []);


  
  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: <span>Dashboard</span>,
          },
        ]}
      />
      <div className={styles.cards}>
        <Card title='Saldo Total' bordered={false}>
          R$ {dashboardData.saldoTotal?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
        </Card>
        <Card title='Total de Check-ins' bordered={false}>
          {dashboardData.totalCheckins ? dashboardData.totalCheckins : 0}
        </Card>
        <Card title='Total de Clientes' bordered={false}>
          {dashboardData.totalClientes}
        </Card>
        <Card title='Total de Faturamento' bordered={false}>
          R$ {dashboardData.totalFaturamento?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
        </Card>
        <Card title='Total de Indicações' bordered={false}>
          {dashboardData.totalIndicacoes ? dashboardData.totalIndicacoes : 0}
        </Card>
        <Card title='Total de Pedidos' bordered={false}>
          {dashboardData.totalPedidos ? dashboardData.totalPedidos : 0}
        </Card>
        <Card title='Total de Restaurantes' bordered={false}>
          {dashboardData.totalRestaurantes ? dashboardData.totalRestaurantes : 0}
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
